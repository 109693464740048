<template>
    <form autocomplete="off" class="">
        <div class="row gy-3">
            <div class="col-12">
                <label for="inputTitle">
                    Status title <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.title"
                    :class="{ 'is-invalid': v$.data.title.$error }"
                    id="inputTitle" name="title" class="form-control"
                    placeholder="Enter title" />
                <div v-for="(item, index) in v$.data.title.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-8">
                <label for="inputStatus">
                    Status <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.status"
                    :class="{ 'is-invalid': v$.data.status.$error }"
                    id="inputStatus" disabled name="status" class="form-control"
                    placeholder="Enter status" />
                <div v-for="(item, index) in v$.data.status.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div> 
            <div class="col-4">
                <label for="inputColor">
                    Choose Color <span class="text-danger small">*</span>
                </label>

                <input type="color" v-model="data.color"
                    :class="{ 'is-invalid': v$.data.color.$error }"
                    id="inputColor" name="color" class="w-100 form-control form-control-color"
                    placeholder="Choose color" />
                <div v-for="(item, index) in v$.data.color.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>   
            <div class="col-12">
                <label class="form-label">Description <span class="text-danger small">*</span></label>
                <textarea v-model="data.description" class="form-control"
                :class="{ 'is-invalid': v$.data.description.$error  }"
                rows="3" placeholder="Write description" name="description"></textarea>
                <div v-for="(item, index) in v$.data.description.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-6">
                <b-form-checkbox v-model="data.visible_to_clerk" name="visible_to_clerk" switch>
                    Visible to clerk
                </b-form-checkbox>
            </div>
            <div v-if="editMode" class="col-6 col-md-3">
                <b-form-checkbox v-model="data.is_disabled" name="is_disabled" switch>
                    Disabled
                </b-form-checkbox>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                title:"",
                status: "",
                color: "#158E4A",
                visible_to_clerk: false,
                is_disabled: false,
                description:"",
            },
        }
    },
    watch: {
        'data.title': function (title){
            this.data.status = this.$filters.slug(title)
        },
    },
    validations() {
        return {        
            data:{
                title: { required: required},  
                status: { required: required},  
                color: { required: required},  
                description: { required: required}           
            },

        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) {this.alertError(this.v$.$errors[0].$message); return };
                let formData = new FormData();
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/case-statuses/create', formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit('addItem', response.data.data)
                        this.$store.commit("caseStatus/ADD_ITEM", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) {this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/case-statuses/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("caseStatus/UPDATE_ITEM", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>