<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="d-flex mb-2 align-items-center justify-content-between">
            <div class="">
                <span v-html="statusBadge(item.status, item.color)"></span>
            </div>
            <slot name="actions"></slot>
        </div>
        <h5 class="mb-0 card-title">{{item.title}}</h5>
        <p v-if="item.description" class="card-text mt-2 mb-0 small">{{item.description}}</p>
        <hr>
        <div>
            <p class="mb-1">Visible to clerk: {{item.visible_to_clerk ? 'YES' : 'NO'}}</p>
            <p class="mb-0">Disabled: {{item.is_disabled ? 'YES' : 'NO'}}</p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    },      
}
</script>

<style>

</style>